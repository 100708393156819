import './App.css';
import GiraffeComponent from './GiraffeComponent';

function App() {
  return (
    <div className="App">
      <GiraffeComponent />
    </div>
  );
}

export default App;
